//Make sure jQuery has been loaded before app.js
if (typeof jQuery === "undefined") {
    throw new Error("Requires jQuery");
}

//
// function itxiFitxa(galdetu) {
//     var galdetu = galdetu | 0
//     if (galdetu == 0 ||
//         typeof ($('.btn-gorde').attr('disabled')) != 'undefined' ||
//         (typeof ($('.btn-gorde').attr('disabled')) == 'undefined' && confirm('Aldaketak gorde gabe jarraitu nahi duzu?'))) {
//         document.getElementById("azpifitxa").innerHTML = '';
//         document.getElementById("azpifitxa").style.display = "none";
//
//         document.getElementById("fitxa").innerHTML = '';
//         document.getElementById("fitxa").style.display = "none";
//
//         document.getElementById("messages-list").className = "col-xs-12";
//         //document.getElementById("datatable").style.width = "100%";
//
//         //document.getElementById("content").className = "col-xs-12 col-sm-10 full-content";
//     }
// }

// function itxiAzpifitxa(galdetu) {
//     var galdetu = galdetu | 0
//     if (galdetu == 0 ||
//         typeof ($('.btn-gorde-azpi').attr('disabled')) != 'undefined' ||
//         (typeof ($('.btn-gorde-azpi').attr('disabled')) == 'undefined' && confirm('Aldaketak gorde gabe jarraitu nahi duzu?'))) {
//         document.getElementById("azpifitxa").innerHTML = '';
//         document.getElementById("azpifitxa").style.display = "none";
//
//         document.getElementById("fitxa").className = "col-xs-6";
//         //document.getElementById("datatable_azpi").style.width = "50%";
//
//         document.getElementById("messages-list").className = "col-xs-6";
//         //document.getElementById("datatable").style.width = "50%";
//
//         //document.getElementById("content").className = "col-xs-12 col-sm-10 full-content";
//     }
// }
//
// function dataEremuaKonfiguratu(hizkuntza) {
//     $('.datepicker').datepicker({
//         dateFormat: 'yy-mm-dd'
//     });
//
//     $.datepicker.setDefaults($.datepicker.regional[hizkuntza]);
// }
//
// function select2Konfiguratu() {
//     $(".select2").select2();
// }
//
// function select2MultipleaKonfiguratu() {
//     $(".select2Multiplea").select2({
//         tags: true,
//         allowClear: true,
//         createTag: function (params) {
//             return {
//                 id: params.term,
//                 text: params.term + ' (Berria)',
//                 newOption: true
//             }
//         }
//     });
// }
//
// function koloreaEremuaKonfiguratu() {
//     $(".pick-a-color").pickAColor();
//
//     $(".pick-a-color").css('background-color', $(".pick-a-color").val());
//
//     $(".pick-a-color").on('change', function () {
//         $(this).css('background-color', $(this).val());
//     });
// }
//
// function gordeBotoiaAktibatu(azpi) {
//     var azpi = azpi | 0;
//
//     if (azpi == 0) {
//         var botoia = $('.btn-gorde');
//         var botoia1 = $('.btn-gorde-fitxa');
//     } else
//         var botoia = $('.btn-gorde-azpi');
//
//     $('input').keyup(function (e) {
//         if (e.keyCode != 9) {
//             botoia.removeAttr('disabled');
//             botoia1.removeAttr('disabled');
//         }
//     });
//
//     $('select').change(function () {
//         botoia.removeAttr('disabled');
//         botoia1.removeAttr('disabled');
//     });
//
//     $('input:checkbox').change(function () {
//         botoia.removeAttr('disabled');
//         botoia1.removeAttr('disabled');
//     });
//
//     $('.datepicker').change(function () {
//         botoia.removeAttr('disabled');
//         botoia1.removeAttr('disabled');
//     });
//
//     $('textarea').keyup(function (e) {
//         if (e.keyCode != 9) {
//             botoia.removeAttr('disabled');
//             botoia1.removeAttr('disabled');
//         }
//     });
//
//     $(".pick-a-color").change(function () {
//         botoia.removeAttr('disabled');
//         botoia1.removeAttr('disabled');
//     });
// }
//
// function beteKonboDependientea(nagusia, dependientea, urla, eremuDep) {
//     var kokapena = dependientea.parent();
//     nagusia.change(function () {
//         loading(kokapena);
//         var datuak = eremuDep + "=" + $(this).val();
//         $.get(urla, datuak,
//             function (data) {
//                 dependientea.empty();
//                 var selected = '';
//                 $.each(data, function (key, element) {
//                     if (key == '0') selected = 'selected=selected';
//                     dependientea.append("<option value='" + key + "'" + selected + " >" + element + "</option>");
//                 });
//                 dependientea.val('0');
//                 noloading();
//             });
//     });
// }