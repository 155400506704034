function atzeraJoDesgaitu() {
    //honekin history galtzen da
    // window.location.hash = "no-back-button";
    // window.location.hash = "Again-No-back-button" //chrome
    //  window.onhashchange = function () {
    //      window.location.hash = "no-back-button";
    //  }
}

function gordeEz() {
    document.cookie = "GORDEGABE=false;  path=/";
    document.cookie = "CKEDITORAKTIBATUGORDE=false;  path=/";
}

function gordeBai() {
    document.cookie = "GORDEGABE=true;  path=/";
}

function gordeBotoiaAktibatu() {
    $('.btn-gorde').removeAttr('disabled');
    gordeBai();
}

function goBack() {
    history.go(-1);
}

function hartuCookie(name) {
    var arg = name + "=";
    var alen = arg.length;
    var clen = document.cookie.length;
    var i = 0;
    while (i < clen) {
        var j = i + alen;
        if (document.cookie.substring(i, j) == arg)
            return "1";
        i = document.cookie.indexOf(" ", i) + 1;
        if (i == 0)
            break;
    }
    return null;
}

function onartu_cookies() {
    var expire = new Date();
    expire = new Date(expire.getTime() + 7776000000);
    document.cookie = "oharra_cookies=onartu; expires=" + expire;

    var visit = hartuCookie("oharra_cookies");
    if (visit == 1) {
        popbox3();
    }
}

function bilatu_cookie(name) {
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + name.replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
}

function popbox3() {
    $('#info-cookies').toggle();
}

function nireLokalizazioak(entitatea) {
    $.get('/entitateak_lokalizazioak/' + entitatea, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", "0");
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            theText = document.createTextNode(data[i].izena);
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#entitateaLokalizazioa_id').html(balioak);
    });
}

function nireOrdezkariLokalizazioak(entitatea) {
    $.get('/entitateak_lokalizazioak/' + entitatea, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", "0");
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            theText = document.createTextNode(data[i].izena);
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#ordezkariaLokalizazioa_id').html(balioak);
    });
}

function nirePostakodeak(herria) {
    $.get('/postakodeak/' + herria, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", 0);
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            theText = document.createTextNode(data[i].pk);
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#postakodea_id').html(balioak);
    });
}

function nireNorbanakoak(entitatea) {
    $.get('/entitateak_norbanakoak/' + entitatea, function (data) {
        if (typeof(aukeratua) === "undefined" || aukeratua === null) aukeratua = 0;
        var balioak = [];
        theOption = document.createElement("option");
        theText = document.createTextNode('--');
        theOption.appendChild(theText);
        theOption.setAttribute("value", 0);
        theOption.setAttribute("selected", true);
        balioak.push(theOption);
        for (var i in  data) {
            theOption = document.createElement("option");
            if (data[i].blokeatua == '0')
                theText = document.createTextNode(data[i].izena + ' ' + data[i].abizenak + ' (' + data[i].mugikorra + ')');
            else
                theText = document.createTextNode('[B] ' + data[i].izena + ' (' + data[i].mugikorra + ')');
            theOption.appendChild(theText);
            theOption.setAttribute("value", data[i].id);
            if (aukeratua == data[i].id) {
                theOption.setAttribute("selected", true);
            }
            balioak.push(theOption);
        }
        $('#norbanakoa_id').html(balioak);
    });
}

function produktuarenDatuak(produktua) {
    $.get('/produktuak/' + produktua, function (data) {
        $('#produktua').val(data.produktua);
        $('#ref_originala').val(data.ref_originala);
        $('#ref_enpresa').val(data.ref_enpresa);
        $('#barrakodea').val(data.barrakodea);
        $('#salmentaPrezioa').val(data.salmentaPrezioa);
        $('#bezMota').val(data.bezMota);
    });
}

/* SCRIPTS FITXA TXIKIAK */

function erroreakIdatzi(xhr, textStatus, thrownError) {
    $('#erroreak').css('display', 'block');
    $(".erroreakZerrenda").text('');
    $.each($.parseJSON(xhr.responseText),
        function (ind, elem) {
            $(".erroreakZerrenda").append('<li>' + elem + '</li>');
        });
}

function gehituBalioaSelecten(e, form, ruta, eremua_select, eremua_berria, div_berria, eremu_nagusia, eremu_nagusia_izena, eremua_select2) {
    var eremu_nagusia = eremu_nagusia || '';
    var eremu_nagusia_izena = eremu_nagusia_izena || '';
    var eremua_select2 = eremua_select2 || '';

    e.preventDefault();
    var form = form.serialize();

    if (eremu_nagusia != '') {
        var balioa = eremu_nagusia.val();
        form = form + '&' + eremu_nagusia_izena + '=' + balioa;
    }
    $.ajax({
        data: form,
        url: '/' + ruta + '/berriaTxikia/',
        success: function (data) {
            var emaitza = data.html;
            var balioa = eremua_berria.val();

            eremua_select.append('<option value="' + emaitza + '" selected>' + balioa + '</option>');
            if (eremu_nagusia != '') {
                eremua_select2.val('');
            }

            if (eremua_select2 != ''){
                eremua_select2.text(balioa);
            }

            //Itxi modala
            //div_berria.css('display', 'none');
            div_berria.modal('toggle');

            //ikusi
            var botoia = eremua_select.attr("id") + '_ikusi';
            botoia = $("#" + botoia);
            if (typeof (botoia) != 'undefined')
                botoia.attr("eremua", eremua_select.val());
        },
        error: function (xhr, textStatus, thrownError) {
            erroreakIdatzi(xhr, textStatus, thrownError)
        }
    });
}

function irakurriCSVfitxeroa(eremuaIzena, urla, eremuaEmaitza) {
    document.body.style.cursor = 'wait';
    var input = document.getElementById(eremuaIzena);
    var eremu_id = document.getElementById('idNagusia');
    var id = 0;
    if (eremu_id != null) id = eremu_id.value;

    var lerroKopOna = 0;

    if (input.value == '') {
        alert('Ez dut topatu fitxategirik.');
        document.body.style.cursor = 'default';
        return false;
    }

    var file = input.files[0];
    var extension = file.name.split('.').pop();

    if (extension != 'csv') {
        alert('Fitxategiaren formatua ez da zuzena. CSV izan behar da.');
        document.body.style.cursor = 'default';
        return false;
    }

    var fr = new FileReader();

    fr.onload = function () {
        var fitxeroa = fr.result;

        fitxeroa = fitxeroa.replace(/\n/g, 'LERRO_SALTO');

        var lerroak = fitxeroa.split('LERRO_SALTO');
        var lerroKop = lerroak.length;

        var ruta = '/' + urla + '/csv/';
        if (id>0) ruta = '/' + urla + '/csv/'+id;

        for (var i = 1; i < lerroKop; i++) {
            if (lerroak[i] != '') {
                lerroKopOna++;
                $.ajax({
                    data: 'f=' + lerroak[i],
                    url: ruta,
                    success: function (data) {
                        if (data.status != '200') {
                            alert(urla + ' Arazoak fitxeroa kargatzeko. Saiatu berriro mesedez');
                        } else {
                            // alert (data.emaitza);
                            if (data.emaitza != '') $('#' + eremuaEmaitza).append(data.emaitza);
                        }
                    },
                    error: function (xhr, textStatus, thrownError) {
                        erroreakIdatzi(xhr, textStatus, thrownError)
                    }
                });
            }
        }
        // $('#emaitza').append(' Guztira: ' + lerroKopOna + ' lerro' );
        document.body.style.cursor = 'default';
    };

    fr.readAsText(file);
}

function formatuakZbkia(nStr)
{
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
}