//Make sure jQuery has been loaded before app.js
if (typeof jQuery === "undefined") {
    throw new Error("Requires jQuery");
}



// function idatziMezua(mezua) {
//     $('#div-mezua').css([
//         "width", "200%",
//         "height", "100%",
//         "margin-bottom", "0px"
//     ]);
//     $('#div-mezua').text("");
//
//     $('#div-mezua').fadeIn(1);
//     $('#div-mezua').append('<i class="fa fa-check fa-1"></i>');
//     $('#div-mezua').append('<spam>' + mezua + '</spam>');
//     $('#div-mezua').fadeOut(6000);
//     itxiFitxa(0);
// }

// function taulaLerroaAktiboa(thisLerroa) {
//     var klasea = thisLerroa.data('klasea');
//     if (klasea != '') thisLerroa.removeClass(klasea);
//
//     $('tr').not(thisLerroa).each(function () {
//         var klasea = $(this).data('klasea');
//         if (klasea != '') $(this).addClass(klasea);
//     });
//
//     $('tr').removeClass('lerroaSelect');
//
//     thisLerroa.addClass('lerroaSelect');
// }

// function mantenduLerroaAktiboa(id) {
//     var thislerroa = $('#lerroa-zb-' + id);
//     $(thislerroa).click();
// }